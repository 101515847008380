import React from 'react'

const Figure = ({ src, alt }) => {
  return (
    <span className="row">
      <span className="figure col-12 text-center">
        <img
          src={src}
          alt={alt}
          className="figure-img img-fluid mx-auto d-block"
        />
        <span className="figure-caption font-italic">{alt}</span>
      </span>
    </span>
  )
}

export default Figure
