import { createElement } from 'react'
import rehypeReact from 'rehype-react'

import Figure from '../components/Figure'
import BlockQuote from '../components/BlockQuote'
import MediaEmbed from '../components/MediaEmbed'
import ExternalLink from '../components/ExternalLink'

const renderAst = new rehypeReact({
  createElement,
  components: {
    img: Figure,
    blockquote: BlockQuote,
    'media-embed': MediaEmbed,
    a: ExternalLink
  }
}).Compiler

export default renderAst
