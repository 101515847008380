import React from 'react'

const BlockQuote = (props) => {
  return (
    <div className='block-quote'>
      {props.children}
    </div>
  )
}

export default BlockQuote
