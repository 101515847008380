import React from 'react'
import Helmet from 'react-helmet'
import { Link } from 'gatsby'
import get from 'lodash/get'
import renderAst from '../helpers/MarkdownCompiler'
import { StaticQuery, graphql } from 'gatsby'

import PageHeader from '../components/PageHeader'
import BlogPostPagination from '../components/BlogPostPagination'
import Layout from '../components/Layout'

class BlogPostTemplate extends React.Component {
  render() {
    const post = this.props.data.contentfulBlogPost
    const siteTitle = get(this.props, 'data.site.siteMetadata.title')
    const { previous, next } = this.props.pageContext
    const featuredImage =
      post &&
      post.featuredImage &&
      post.featuredImage.file &&
      post.featuredImage.file.url

    const excerpt =
      post &&
      post.content &&
      post.content.childMarkdownRemark &&
      post.content.childMarkdownRemark.excerpt

    const pageTitle = post.metaTitle || post.title
    return (
      <Layout>
        <div className="post">
          <Helmet title={pageTitle}>
            <meta property="og:title" content={post.title} />
            <meta property="og:description" content={excerpt} />
            <meta
              property="og:url"
              content={`https://blog-tierion-com.netlify.com/${post.slug}`}
            />
            <meta property="og:image" content={featuredImage} />
            <meta name="twitter:card" content="summary_large_image" />
            <meta name="twitter:site" content="@tierion" />
            <meta name="twitter:creator" content="@tierion" />
            <meta name="twitter:title" content={post.title} />
            <meta name="twitter:description" content={excerpt} />
            <meta name="twitter:image" content={featuredImage} />
          </Helmet>
          <PageHeader title={post.title} subtitle={post.publishedAt} />
          <div className="container-fluid">
            <div className="post-body mx-auto col-12 col-sm-11 col-md-10 col-lg-8 col-xl-6">
              {renderAst(post.content.childMarkdownRemark.htmlAst)}
            </div>
          </div>
          <div className="container-fluid keep-reading">
            <div className="mx-auto text-center">
              <h4>Keep Reading</h4>
            </div>
            <div className="mx-auto col-12 col-sm-11 col-lg-10">
              <BlogPostPagination {...this.props.pageContext} />
            </div>
          </div>
        </div>
      </Layout>
    )
  }
}

export default BlogPostTemplate

export const pageQuery = graphql`
  query BlogPostBySlug($slug: String!) {
    contentfulBlogPost(slug: { eq: $slug }) {
      title
      publishedAt(formatString: "MMMM DD, YYYY")
      slug
      content {
        childMarkdownRemark {
          htmlAst
          excerpt
        }
      }
      metaDescription {
        metaDescription
      }
      metaTitle
      featuredImage {
        file {
          url
        }
      }
    }
  }
`
