import React, { Component } from 'react'
import axios from 'axios'
import { navigate } from 'gatsby'

import githubIcon from '../images/github.svg'
// TODO: create a fallback for cases where window.twttr isn't included
// TODO: either inject or fallback to nonjs version of the tweet
// this requires twttr to be on the page
class TwitterEmbed extends Component {
  componentDidMount() {
    if (window.twttr) {
      twttr.ready(twitter => {
        twitter.widgets.createTweetEmbed(
          this.props.tweetId,
          this.refs.twitterEmbed
        )
      })
    }
  }
  render() {
    return (
      <span className="d-flex">
        <span className="mx-auto">
          <span ref="twitterEmbed" />
        </span>
      </span>
    )
  }
}

// showinfo = 0 removes the title bar
// modestbranding = 1 moves youtube logo
const YoutubeEmbed = ({ videoId }) => (
  <span className="mx-auto youtube-embed d-block">
    <iframe
      width="560"
      height="320"
      src={`https://www.youtube.com/embed/${videoId}?modestbranding=1&showinfo=0`}
      frameBorder="0"
      allowFullScreen
    />
  </span>
)

class SoundcloudEmbed extends Component {
  constructor(props) {
    super(props)
    this.state = {
      html: '<span>Soundcloud Player Loading</span>'
    }
  }
  componentDidMount() {
    if (!this.props.src) return

    axios('https://soundcloud.com/oembed', {
      params: {
        url: this.props.src
      }
    })
      .then(({ data }) => {
        this.setState({
          html: data.html
        })
      })
      .catch(err => console.error(err))
  }
  render() {
    return (
      <span className="d-flex">
        <span className="w-100">
          <span dangerouslySetInnerHTML={{ __html: this.state.html }} />
        </span>
      </span>
    )
  }
}

class WistiaEmbed extends Component {
  componentDidMount() {
    this.wistiaScript = document.createElement('script')
    this.wistiaScript.src = `https://fast.wistia.com/embed/medias/${
      this.props.videoId
    }.jsonp`
    this.wistiaScript.async = true
    document.body.appendChild(this.wistiaScript)
  }
  componentWillUnmount() {
    document.body.removeChild(this.wistiaScript)
  }

  render() {
    return (
      <span className="d-flex">
        <span className="w-100">
          <span
            className={`wistia_embed wistia_async_${
              this.props.videoId
            } videoFoam=true`}
          />
        </span>
      </span>
    )
  }
}

class GithubEmbed extends Component {
  constructor(props) {
    super(props)
    this.state = {
      stars: null,
      description: null,
      name: this.props.src,
      image: null
    }
  }
  componentDidMount() {
    const repo = this.props.src.replace(/(https?:\/\/github.com\/)/, '')
    axios(`https://api.github.com/repos/${repo}`)
      .then(({ data }) => {
        const { description, stargazers_count, name, owner } = data
        this.setState({
          stars: stargazers_count,
          image: owner && owner.avatar_url,
          description,
          name
        })
      })
      .catch(err => console.error(err))
  }
  render() {
    return (
      <span className="d-flex">
        <a href={this.props.src}>
          <span className="github-embed d-flex flex-column">
            <span className="repo-link text-right">
              {/* TODO: Change to span from a, since already wrapped */}
              {/* <a href={this.props.src} /> */}
              <img src={githubIcon} alt="github icon" />
            </span>
            <span className="d-flex flex-column flex-md-row align-items-center">
              <span className="repo-logo">
                {this.state.image ? (
                  <img src={this.state.image} className="img-fluid p-2" />
                ) : null}
              </span>
              <span className="ml-0 ml-md-4">
                <span className="repo-name mb-2">{this.state.name}</span>
                <span className="repo-description">
                  {this.state.description}
                </span>
              </span>
            </span>
          </span>
        </a>
      </span>
    )
  }
}

const MediaEmbed = ({ src }) => {
  let component = null
  const tweetMatch = src.match(/status\/(\d+)/)
  const youtubeMatch = src.match(
    /(?:youtube\.com\/(?:[^\/]+\/.+\/|(?:v|e(?:mbed)?)\/|.*[?&]v=)|youtu\.be\/)([^"&?\/ ]{11})/
  )
  const soundcloudMatch = src.match(/soundcloud/)
  const wistiaMatch = src.match(/wistia/)
  const githubMatch = src.match(/github.com/)
  if (tweetMatch) {
    component = <TwitterEmbed tweetId={tweetMatch[1]} />
  } else if (youtubeMatch) {
    component = <YoutubeEmbed videoId={youtubeMatch[1]} />
  } else if (soundcloudMatch) {
    component = <SoundcloudEmbed src={src} />
  } else if (wistiaMatch) {
    component = <WistiaEmbed videoId={src.match(/medias\/(.*)$/)[1]} />
  } else if (githubMatch) {
    component = <GithubEmbed src={src} />
  }

  return component
}

export default MediaEmbed
