import React from 'react'
import { Link } from 'gatsby'

import thumbnailPlaceholder from '../images/blog-post-placeholder.png'

const BlogPostLink = ({ slug, title, thumbnail, prev }) => {
  return (
    <div className="blog-post--link col-12 col-sm-6 p-0 mx-auto">
      <div className="link--content mx-auto mt-4">
        <Link to={`/${slug}`} rel={prev ? 'prev' : 'next'}>
          <span
            className="blog-post--thumbnail"
            style={{
              backgroundImage: `url(${thumbnail || thumbnailPlaceholder})`
            }}
          />
          <span className="link--footer">
            <span className="title">{`${(!prev && '←') ||
              ''} ${title} ${(prev && '→') || ''}`}</span>
          </span>
        </Link>
      </div>
    </div>
  )
}

const BlogPostPagination = ({ previous, next }) => {
  const nextThumb =
    next &&
    next.featuredImage &&
    next.featuredImage.file &&
    next.featuredImage.file.url

  const prevThumb =
    previous &&
    previous.featuredImage &&
    previous.featuredImage.file &&
    previous.featuredImage.file.url

  return (
    <div className="blog-post--pagination">
      <div className="row">
        {next ? (
          <BlogPostLink
            slug={next.slug}
            title={next.title}
            thumbnail={nextThumb}
          />
        ) : null}
        {previous ? (
          <BlogPostLink
            slug={previous.slug}
            title={previous.title}
            thumbnail={prevThumb}
            prev
          />
        ) : null}
      </div>
    </div>
  )
}

export default BlogPostPagination
